import React from 'react';
import Helmet from '../components/helmet';
import Navbar from './navbar';
import CovidBanner from './covid-banner';
import { Link } from 'gatsby'
import '../components/style.scss';

const Header = (props) => (
	<div>
		<Helmet />

		<section className="hero imageBg is-fullheight-with-navbar">
			<div className="sticky-container">
				<CovidBanner />
				<Navbar />
			</div>
			<div className="hero-body">
				<div className="container">
					<div className="is-half banner-text-container">
						<h1 className="is-size-3-widescreen">
							Effective, Professional Help & Advice
						
						</h1>
					</div>
					<div className="home-header-cta-bar blue-cta level">
						<div className="cta-left">
							<h2 className="is-hidden-mobile is-size-4-tablet is-size-3-widescreen">
								If you need further advice, we’re here to help.
					</h2>
						</div>
						<div className="cta-right">
							<Link className="button" to="/contact-us">Request a call back</Link>
						</div>
					</div>
				</div>
			</div >
		</section >
	</div>
);

export default Header;
