import React from 'react';
import { Link } from 'gatsby'
import { FaChevronRight } from 'react-icons/fa'
// import './style.scss';



const CovidBanner = () => (
    <section className="hero covid-advice is-flex-mobile">
        <section className="covid-section level">
            <div className="co-block-left level-left"><Link className="is-size-7-touch level-item covid-left" to="/contact-us">Coronavirus (COVID-19): We’re still here to help</Link></div>
            <div className="co-block-right level-right"><Link className="level-item covid-right is-hidden-touch" to="/contact-us">Our Insolvency Practitioners are available throughout the current restrictions to provide help and advice to you and your business <span className="faChev"><FaChevronRight /></span></Link></div>


        </section>
    </section>

);


export default CovidBanner;