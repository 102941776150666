import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'
import Img from "gatsby-image"

export const query = graphql`
    query($slug:String!) {
        markdownRemark(fields: {slug: { eq: $slug } }) {
            frontmatter {
                title
                date
                image {
                    childImageSharp {
                        fluid(maxWidth: 404, maxHeight: 280, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
            }
}
            }
            html
        }
    }
`

const News = (props) => {
    return (
        <div>
            <Header />
            <div className="news"></div>
            <section className="section">
                <div className="container content">
                    <div className="hero newsImage">
                        <Img fluid={props.data.markdownRemark.frontmatter.image.childImageSharp.fluid} />

                    </div>
                    <h1>{props.data.markdownRemark.frontmatter.title}</h1>
                    <p>{props.data.markdownRemark.frontmatter.date}</p>
                    <div dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}></div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default News